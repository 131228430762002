import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
// hooks
import siteConfig from "../utils/site";
import useAuth from "../hooks/useAuth";
// routes
import { PATH_PAGE, PATH_PAGE_STORE, PATH_DASHBOARD } from "../routes/paths";

// ----------------------------------------------------------------------

SiteGuestGuard.propTypes = {
  site: PropTypes.string,
  children: PropTypes.node,
};

export default function SiteGuestGuard({ site = "ROOT", children }) {
  const { site: current_site } = useAuth();

  // if (!current_site) {
  //   window.location.reload();
  // }

  if (current_site?.type_site !== site) {
    switch (current_site?.type_site) {
      case "STORE":
        // console.log("redirection here...", current_site?.type_site);
        // window.location.reload();
        return <Navigate to={PATH_PAGE_STORE.home} />;
      default:
        // console.log("redirection not found...", current_site?.type_site);
        // window.location.reload();
        return <Navigate to={PATH_PAGE.home} />;
    }
  }

  // if (isAuthenticated) {
  //   return <Navigate to={PATH_DASHBOARD.root} />;
  // }

  return <>{children}</>;
}
