import PropTypes from "prop-types";
import { useMemo, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import useSettings from "../hooks/useSettings";
import palette from "./palette";
import { typographies } from "./typography";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import useAuth from "../hooks/useAuth";

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { themeMode, themeDirection, typographyMode, onChangeLayout } = useSettings();
  const { site: current_site } = useAuth();

  const palettes = {
    light: palette.light,
    dark: palette.dark,
    default: palette.default,
    blue: palette.blue,
    orange: palette.orange,
    cyan: palette.cyan,
    red: palette.red,
    purple: palette.purple,
  };

  const selectedPalette = palettes[current_site?.theme_mode ?? themeMode];

  const selectedTypography = typographies[current_site?.typography_mode ?? typographyMode];

  const isLight = themeMode === "light";

  const themeOptions = useMemo(
    () => ({
      palette: selectedPalette,
      typography: selectedTypography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [selectedPalette, isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  useEffect(() => {
    onChangeLayout(current_site?.layout_mode);
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
